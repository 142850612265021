<template>
  <layout>
    <div v-if="employee" class="schedule mt-3">
      <div class="schedule__filters row">
        <h2 class="col-4">{{ employee.name }}</h2>
      </div>
      <b-card class="schedule__card">
        <div class="schedule__content">
          <b-form-group
            label="Timezone"
            label-for="time-zone"
            class="d-flex align-items-center"
          >
            <b-form-select
              disabled
              id="time-zone"
              v-model="timeZone"
              :options="zones"
              required
              class="ml-2 schedule__select"
            />
          </b-form-group>
        </div>
        <table class="schedule__calendar calendar">
          <thead class="calendar__header">
          <tr>
            <th v-for="(day, index) in days" :key="index" class="calendar__header-item item">{{ day.name }}</th>
            <th class="calendar__header-item item time"></th>
          </tr>
          </thead>
          <tbody class="schedule__calendar__body">
          <tr v-for="(time, i) in 24" :key="time" class="calendar__body-hour">
            <td
              v-for="(day, index) in days"
              :key="index"
              class="calendar__body-item item"
              :class="{'calendar__body-item_active': selects.some(e => e.index === index && e.time === time)}"
            ></td>
            <td class="calendar__header-item item time">{{ formatTime(i) }}-{{ formatTime(time) }}</td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between mt-2 schedule__content">
          <button @click="openUser" class="schedule__save btn btn-secondary">Back</button>
        </div>
      </b-card>
    </div>
  </layout>
</template>

<script>
export default {
  name: 'Schedule',
  data() {
    return {
      timeZone: 0,
      days: [
        { name: 'M' }, { name: 'T' }, { name: 'W' }, { name: 'Th' }, { name: 'F' }, { name: 'St' }, { name: 'S' },
      ],
      isMouseClicked: false,
      selects: [],
      firstX: null,
      firstY: null,
      prevX: null,
      prevY: null,
      lastX: null,
      lastY: null,
    }
  },
  computed: {
    zones() {
      const res = []
      for (let i = -12; i < 15; i++) {
        res.push({ text: `UTC${i >= 1 ? '+' + i : i ? i : '-' + i}`, value: i })
      }
      return res
    },
    employee() {
      return this.$store.getters["employees/employee"];
    },
  },
  methods: {
    formatTime (time) {
      const result = new Date((time || 0) * 1000).toISOString().substr(11, 8)
      return result.substr(6, 2) + ':00'
    },
    openUser () {
      this.$router.push(`/employees/${this.$route.params.id}`)
    },
    clear () {
      this.selects = []
    },
    toOfficeFormat () {
      this.selects = []
      for (let i = 0; i < 5; i++) {
        for (let j = 11; j < 20; j++) {
          if (j !== 15) {
            this.selects.push({ index: i, time: j })
          }
        }
      }
    },
    calculateTime (hour, timezone) {
      if (hour < Math.abs(timezone) && timezone < 0) {
        return hour + 24 + timezone + 1
      }
      return (hour + timezone) % 24 + 1
    },
    calculateDay (day, timezone, hour) {
      if (hour < Math.abs(timezone) && timezone < 0) {
        return day - 1
      }
      if (hour + timezone + 1 > 23) {
        return (day + 1) % 7
      }
      return day
    }
  },
  async mounted() {
    await this.$store.dispatch('employees/getEmployeeById', this.$route.params.id);
    this.timeZone = -(new Date().getTimezoneOffset()/60)
    this.employee.schedule?.forEach((e) => {
      if (!this.selects.some(e => e.index === this.calculateDay(e.day_of_week, e.timezone, e.hour) && e.time === this.calculateTime(e.hour, e.timezone))) {
        this.selects.push({ index: this.calculateDay(e.day_of_week, e.timezone, e.hour), time: this.calculateTime(e.hour, e.timezone) })
      }
      this.timeZone = e.timezone
    })
  },
  async beforeDestroy() {
  },
}
</script>

<style lang="scss" scoped>
.calendar {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.item {
  width: 50px;
  height: 24px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
}
.time {
  width: 150px;
  border: 1px solid #dbe0f9;
}
.calendar__body-item {
  background-color: #e7e7ee;
  cursor: pointer;
}
.calendar__body-item_active {
  background-color: #00be9b;
}
.calendar__header {
  background-color: var(--primary);
  color: var(--light);
}
.schedule__select {
  width: 200px;
  cursor: pointer;
}
.schedule__card {
  width: 520px;
}
</style>
